<template>
  <div class="container-fluid">
    <b-card>
      <b-row class="row-style" no-gutters>
        <b-col class="mt-2 ml-3" :cols="getTopNavMenuCol()">
          <d-body-nav-bar
            :tabs="$store.getters['menu/getTabs'].creditPack"
            @tab-changed="onTabChanged"
          />
        </b-col>
        <b-col v-if="$store.getters['layout/getInnerWidth'] > 1332" class="mt-3 text-right mr-2" cols="5">
          <d-button
            text="views.subscriptions.index.new-credit-pack"
            class="ml-2 d-btn btn d-btn-primary-new font-text-title"
            icon="fa fa-plus mr-1"
            @on:button-click="$router.push({ name: 'credits_pack_new' })"
          />
        </b-col>
        <b-col v-if="$store.getters['layout/getInnerWidth'] > 1332" class="mt-3 mr-3">
          <div class="has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('components.doinsport.table.search')"
              v-model="filterCreditsPacks"
            >
          </div>
        </b-col>
      </b-row>
      <b-row v-if="$store.getters['layout/getInnerWidth'] < 1332">
        <b-col :class="getColsClasses()" :cols="getBtnsCols()">
          <d-button
            text="views.subscriptions.index.new-credit-pack"
            class="btn-full-width ml-2 d-btn btn d-btn-primary font-text-title"
            icon="fa fa-plus mr-1"
            @on:button-click="$router.push({ name: 'credits_pack_new' })"
          />
        </b-col>
        <b-col :class="getColsClasses()" :cols="getBtnsCols()">
          <div class="btn-full-width has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('components.doinsport.table.search')"
              v-model="filterCreditsPacks"
            >
          </div>
        </b-col>
      </b-row>
      <credit-pack-table
        :filter="filterCreditsPacks"
        :is-archived="isArchived"
      />
    </b-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectedTab: 'views.credits.credits-pack',
    filterCreditsPacks: '',
    isArchived: false,
  }),
  components: {
    CreditPackTable: () => import('./CreditPackTable'),
  },
  methods: {
    onTabChanged(scope) {
      this.filterCreditsPacks = '';
      this.isArchived = (scope.name === 'views.credits.rar' && scope.active)
    },
    getCardCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1332 && width >= 1185) {
        return 'col-md-4';
      } else if (width < 1185) {
        return 'col-md-6';
      } else {
        return 'col-md-3';
      }
    },
    getColsClasses() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-2';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '5';
      }
    },
    getBtnsCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '3';
      } else {
        return '6';
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import "@lazy/_b-card.scss";
</style>
